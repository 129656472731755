import { Injectable } from "@angular/core";

import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { Observable } from "rxjs";

import { Auth } from "aws-amplify";

import { NavDisplayService } from "../core/nav-display.service";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private navDisplayService: NavDisplayService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // if (location.hostname === "localhost") {
    //   this.navDisplayService.displayNav(true);
    //   return true;
    // } else {
    return Auth.currentAuthenticatedUser()
      .then(data => {
        localStorage.removeItem("redirected");
        this.navDisplayService.displayNav(true);
        return true;
      })
      .catch(err => {
        if (location.href.indexOf("code=") === -1) {
          localStorage.setItem("redirected", "true");
          location.assign(
            "http://id.chadbumstead.com/login?response_type=code&client_id=467qh56gocjfb6tld74atupb11" +
              "&redirect_uri=http://shf.chadbumstead.com"
          );
          return false;
        } else {
          location.assign("http://shf.chadbumstead.com/private");
          return false;
        }
      });
    //}
  }
}
