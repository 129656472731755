import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { AmplifyAngularModule, AmplifyService } from "aws-amplify-angular";
import { PrivateComponent } from "./private/private.component";

import { AuthGuard } from "./core/auth.guard";
import { NavDisplayService } from "./core/nav-display.service";

@NgModule({
  declarations: [AppComponent, PrivateComponent],
  imports: [BrowserModule, AppRoutingModule, AmplifyAngularModule],
  providers: [AmplifyService, AuthGuard, NavDisplayService],
  bootstrap: [AppComponent]
})
export class AppModule {}
