import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class NavDisplayService {
	public navDisplayStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	displayNav(value: boolean) {
		this.navDisplayStatus.next(value);
	}
}
